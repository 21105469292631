import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SnackbarService {
  message = new BehaviorSubject<string>(null);

  close(): void {
    this.message.next(null);
  }

  setSnackbarItem(message: string): void {
    this.message.next(message);
  }
}
