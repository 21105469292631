import {Component} from '@angular/core';
import { VERSION } from 'src/app/version';

@Component({
  selector: 'h-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss']
})
export class PublicFooterComponent {
  version = VERSION;
}
