import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LandingComponent} from './landing/landing.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '../shared/shared.module';

const appRoutes: Routes = [{
  path: '',
  component: LandingComponent
}];

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule.forChild(appRoutes)
  ],
  declarations: [
    LandingComponent
  ],
  providers: [],
  exports: []
})
export class PublicModule {
}
