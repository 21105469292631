import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Injectable()
export class RoutingService {
  constructor(private router: Router, private location: Location) {
  }

  back() {
    this.location.back();
  }

  toConsent() {
    this.router.navigate(['auth', 'consent']);
  }

  toLogin() {
    this.router.navigate(['auth', 'login']);
  }

  toLogout() {
    this.router.navigate(['auth', 'logout']);
  }

  toPatients() {
    this.router.navigate(['private', 'patients']);
  }

  toNoDelegations() {
    this.router.navigate(['auth', 'no-delegation']);
  }

  toManageDelegations() {
    this.router.navigate(['private', 'delegations']);
  }
}
