import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Session, SessionPointer, SessionStatus} from './session';

@Injectable()
export class SessionService {
  constructor(private httpClient: HttpClient) {
  }

  createSession(): Observable<SessionPointer> {
    return this.httpClient.post<SessionPointer>('/api/auth/session', {});
  }

  getSessionStatus(token: string): Observable<SessionStatus> {
    return this.httpClient.get<SessionStatus>(`/api/auth/session/${token}/status`);
  }

  giveConsent(): Observable<Session> {
    return this.httpClient.put<Session>(`/api/auth/session/consent`, {});
  }
}
