import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [{
  path: '**',
  redirectTo: '' // TODO add 404 page
}];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
        scrollPositionRestoration: 'top'
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
