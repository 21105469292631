import {Component, EventEmitter, HostBinding, HostListener, OnDestroy, OnInit, Output} from '@angular/core';
import {Dialog, DialogService} from './dialog.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'h-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy {
  @HostBinding('class.dialog') true;
  @HostBinding('class.dialog--visible') visible = false;

  @Output() confirmed = new EventEmitter();

  dialog: Dialog;

  private dialogSubscription: Subscription;

  constructor(private dialogService: DialogService) {
  }

  ngOnInit(): void {
    this.dialogService.dialog.subscribe(dialog => {
      this.visible = true;
      this.dialog = dialog;
    });
  }

  ngOnDestroy(): void {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  @HostListener('click', ['$event'])
  cancel() {
    if (this.dialog.allowCancel) {
      this.visible = false;
      this.dialog.callback(false);
    }
  }

  confirm() {
    this.visible = false;
    this.dialog.callback(true);
  }
}
