import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler, LOCALE_ID} from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import {registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RollbarErrorHandler, rollbarFactory, RollbarService} from './rollbar.service';
import {PublicModule} from './public/public.module';
import {SharedModule} from './shared/shared.module';

registerLocaleData(localeNl);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {enabled: environment.production}),
    BrowserAnimationsModule,
    PublicModule,
    SharedModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {provide: ErrorHandler, useClass: RollbarErrorHandler},
    {provide: RollbarService, useFactory: rollbarFactory},
    {provide: LOCALE_ID, useValue: 'nl'}
  ]
})
export class AppModule {
}
