<div class="dialog-window" *ngIf="visible" (click)="$event.stopPropagation()">
  <h2>{{dialog.title}}</h2>

  <p>{{dialog.body}}</p>

  <div class="action-bar">
    <button class="button button--secondary" (click)="cancel()" *ngIf="dialog.allowCancel">annuleer</button>
    <button class="button button--primary" (click)="confirm()">{{dialog.confirmText}}</button>
  </div>
</div>

