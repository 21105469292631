import {Injectable} from '@angular/core';

export interface FlashMessage {
  title: string;
  message: string;
}

@Injectable()
export class FlashMessageService {
  flashMessage: FlashMessage;

  closeFlashMessage(): void {
    this.flashMessage = null;
  }

  setFlashMessage(title: string, message: string): void {
    this.flashMessage = {
      title: title,
      message: message
    };
  }
}
