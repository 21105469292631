<h-public-navigation-bar></h-public-navigation-bar>

<div class="intro-section">
  <h2>Samenwerken kan makkelijker</h2>

  <p>
    Sinds begin 2017 is Nedap begonnen om de samenwerking tussen zorgprofessionals onderling en specifiek de huisartsen
    te verbeteren. We zagen namelijk dat de digitalisering van het papieren zorgdossier ertoe leidde dat het inzicht in
    elkaars dossier verdween.
    Daardoor ontstond er een situatie waarbij er weer geprint werd en er een foutgevoelige, niet actuele situatie werd
    gecreëerd. Naar aanleiding van diverse gesprekken met zowel de huisartsen als de zorgprofessionals zijn we tot de
    eerste oplossingen gekomen om de samenwerking te verbeteren.
  </p>

  <p>
    Een van de eerste stappen die we hebben verkend zit op het vlak van communicatie tussen beide partijen. Elkaar
    eenvoudig en veilig een bericht kunnen sturen van dossier naar dossier. Daarnaast hebben in diverse regio’s
    bijgedragen om het gewenste inzicht in het zorgdossier terug te krijgen. Door middel van een ‘proof-of-concept’ zijn
    de verkenningen rondom “inzicht en communicatie” getoetst en positief bevonden. Inmiddels zijn er diverse partijen
    die communicatiemogelijkheden hebben toegevoegd in hun eigen applicaties. Een enkeling heeft het zelfs al mogelijk
    gemaakt om ook inzicht in de dossiers te bieden.
  </p>

  <p>
    Het hiervoor beschreven ‘proof-of-concept’ is ontwikkeld op basis van de uitgangspunten van het Nuts initiatief
    (https://nuts.nl/), dankzij Nuts is iedere partij in staat om dezelfde inzicht aan te bieden.
    Hierbij zien we een groot voordeel ontstaan, er hoeven namelijk geen één-op-één koppelingen meer gebouwd te worden.
    En de huisarts of zorgverlener kan voor het dagelijks gebruik in zijn of haar eigen bron applicatie blijven werken.
  </p>

  <p>
    Wij willen deze kennis en inzichten die we verkregen hebben ook gaan inzetten voor een bredere setting van
    zorgprofessionals. Nog steeds met de uitgangspunten van het Nuts initiatief. Elkaar vinden en vertrouwen voor
    bijvoorbeeld de acute nachtzorg is daarvoor een voorbeeld dat we nu, samen met klanten, aan het verkennen zijn.
    Daarom gaan we graag in de toekomst verder met het verbeteren van de samenwerking tussen de verschillende
    zorgaanbieders.
  </p>

  <p>
    En dan doen we graag samen met jullie. Heb je vragen, of zie je kansen waar we een gezamenlijk een verbeterslag
    kunnen slaan? Aarzel niet en neem contact met ons op via <a href="mailto:mark.weernink@nedap.com">Mark Weernink</a>!
    Heb je vragen over Nedap Ons ga dan naar <a
    href="https://nedap-healthcare.com/oplossingen/ons/koppelen-en-samenwerken/">https://nedap-healthcare.com/oplossingen/ons/koppelen-en-samenwerken/</a>
  </p>
</div>

<h-public-footer></h-public-footer>
