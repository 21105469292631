import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export interface Dialog {
  title: string;
  body: string;
  confirmText: string;
  allowCancel: boolean;
  callback: (boolean) => void;
}

@Injectable()
export class DialogService {

  dialog = new Subject<Dialog>();

  showDialog(title: string, body: string, confirmText: string, allowCancel = true): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.dialog.next({title, body, confirmText, allowCancel, callback: (confirmed) => {
        observer.next(confirmed);
        observer.complete();
      }});
    });
  }
}
