import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FocusHeaderComponent} from './focus-header/focus-header.component';
import {SnackbarService} from './snackbar/snackbar.service';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {FlashMessageComponent} from './flash-message/flash-message.component';
import {FormsModule} from '@angular/forms';
import {FlashMessageService} from './flash-message/flash-message.service';
import {RoutingService} from './routing.service';
import {PublicFooterComponent} from './public-footer/public-footer.component';
import {CenterContentComponent} from './center-content/center-content.component';
import {MessageComponent} from './message/message.component';
import {LoadingComponent} from './loading/loading.component';
import {CardComponent} from './card/card.component';
import {SessionComponent} from './session/session.component';
import {SessionService} from './session/session.service';
import {QRCodeModule} from 'angularx-qrcode';
import {DialogComponent} from './dialog/dialog.component';
import {DialogService} from './dialog/dialog.service';
import {StateService} from './state.service';
import {HttpClientModule} from '@angular/common/http';
import {DebounceModule} from 'ngx-debounce';
import {BadgeComponent} from './badge/badge.component';
import {PublicNavigationBarComponent} from './public-navigation-bar/public-navigation-bar.component';
import {PublicDeprecationBarComponent} from './public-deprecation-bar/public-deprecation-bar.component';

@NgModule({
  imports: [
    QRCodeModule,
    DebounceModule,
    CommonModule,
    FormsModule,
    RouterModule,
    HttpClientModule
  ],
  declarations: [
    CardComponent,
    CenterContentComponent,
    DialogComponent,
    FlashMessageComponent,
    FocusHeaderComponent,
    LoadingComponent,
    MessageComponent,
    PublicDeprecationBarComponent,
    PublicNavigationBarComponent,
    PublicFooterComponent,
    SearchBarComponent,
    SessionComponent,
    SnackbarComponent,
    BadgeComponent,
  ],
  providers: [
    DialogService,
    FlashMessageService,
    RoutingService,
    SessionService,
    SnackbarService,
    StateService
  ],
  exports: [
    CardComponent,
    CenterContentComponent,
    DialogComponent,
    FlashMessageComponent,
    FocusHeaderComponent,
    MessageComponent,
    LoadingComponent,
    PublicDeprecationBarComponent,
    PublicNavigationBarComponent,
    PublicFooterComponent,
    SearchBarComponent,
    SnackbarComponent,
    SessionComponent,
    BadgeComponent
  ]
})
export class SharedModule {
}
