import {Component} from '@angular/core';

@Component({
  selector: 'h-public-navigation-bar',
  templateUrl: './public-navigation-bar.component.html',
  styleUrls: ['./public-navigation-bar.component.scss']
})
export class PublicNavigationBarComponent {
  showMenu = false;
}
