import { ErrorHandler, Injectable, InjectionToken, Injector } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';

const rollbarConfig = {
  accessToken: '672a197cdb3a4406a8521eaed7a38eb0',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.production,
  payload: {
    environment: environment.production ? 'production' : 'development'
  }
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(err: any): void {
    if (!environment.production) {
      console.log('Throwing rollbar error in non-production env.');
      throw err;
    }
    const rollbar = this.injector.get(RollbarService);
    rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
